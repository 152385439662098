.reactMarkDown p,li{
    /* white-space: pre-wrap; */
    /* cursor:pointer; */
    /* list-style: none; */
    /* margin:5px 0px 2px 0px;
    position:relative; */
    /* padding: 5px 10px; */
    background: none;
    /* align-items: flex-start;
    border-radius: 12px; */
    font-size:14px
}
.reactMarkDown a{
  text-decoration: none;
  color:black
}
/* .reactMarkDown li p {
  margin: 0;
  padding: 0;
  white-space: pre-wrap;
} */
/* .reactMarkDown p:hover{
    background: #fff;
    border-left: 5px solid #F05F40;
    box-shadow: 0px 6px 9px 0px #00000014;
  } 
  .reactMarkDown li:hover{
    background: #fff;
    border-left: 5px solid #F05F40;
    box-shadow: 0px 6px 9px 0px #00000014;
  }  */